<template>
  <main class="page__content--wider">
    <div class="page__head">
      <div
        class="page__head__container align-items-center justify-content-between"
      >
        <div class="page__head__text">
          <h1 class="page__head2__title">Installments</h1>
        </div>
        <div class="page__head__actions">
          <div class="d-flex">
            <div class="align-self-center mx-2 dropdown">
              <button
                class="button button--grey text--capital text--small d-flex align-items-center"
                type="button"
                @click="toggleFilterModal"
              >
                <span class="filter_text">filter</span>
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
            </div>

            <div class="align-self-center mx-2 dropdown">
              <button
                class="button button--grey text--capital text--small d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                export
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  href="javascript:void(0)"
                  @click="pdfDownload"
                  v-if="userCanExportToPDF"
                  class="dropdown-item text--capital"
                  >PDF</a
                >
                <a
                  href="javascript:void(0)"
                  @click="processDownload"
                  v-if="userCanExportToExcel"
                  class="dropdown-item text--capital"
                  >excel</a
                >
                <!-- <download-excel
                  class="dropdown-item text--capital"
                  style="cursor: pointer"
                  :data="download"
                  :fields="json_fields"
                  name="installment.xls"
                  v-if="!installmentLoading"
                >
                  excel
                </download-excel> -->
                <!-- <a class="dropdown-item text--capital" @click="downloadCSVFile"
                  >CSV</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mt-5"
      style="display: flex; width: 50%; font-size: 14px"
      v-if="filterText"
    >
      <div style="width: 80%">
        {{ filterText }}
      </div>
      <div style="display: flex; width: 10%">
        <span style="cursor: pointer; font-size: 17px" @click="editFilter">
          <i style="padding-right: 5px" class="bi bi-pencil-square"></i>
        </span>
        <span style="cursor: pointer; font-size: 17px" @click="deleteFilter">
          <i style="padding-left: 5px" class="bi bi-x-circle-fill"></i>
        </span>
      </div>
    </div>

    <div class="page__body w-100" style="overflow-x: scroll">
      <table class="customtable _customtable">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Due Date
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Loan Name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Loan Account ID
            </th>
            <!-- <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Account Holder Name
            </th> -->
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Account Officer
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              State
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Repayment Note
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Paid Date
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Principal Due
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Principal Paid
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Interest Due
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Interest Paid
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Fees Due
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Fees Paid
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Penalty Due
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Penalty Paid
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Total Due
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              Total Paid
            </th>
          </tr>
        </thead>
        <tbody v-if="installmentLoading">
          <tr>
            <td colspan="6">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="allInstallments.data != ''">
          <tr v-for="(account, i) in allInstallments.data" :key="i">
            <td>
              <span class="table__body__text table__text text--capital">
                {{ account.due | moment }}</span
              >
            </td>
            <td style="word-break: break-word; padding-right: 10px !important">
              <span class="table__body__text table__text text--capital">{{
                account.loanName
              }}</span>
            </td>
            <td style="word-break: break-word; padding-right: 10px !important">
              <span class="table__body__text table__text text--capital">{{
                account.loanId
              }}</span>
            </td>
            <!-- <td style="word-break: break-word; padding-right: 10px !important">
              <span
                class="table__body__text table__text text--capital text--primary"
              >
                {{ account.accountHolder }}
              </span>
            </td> -->
            <td style="word-break: break-word; padding-right: 10px !important">
              <span
                class="table__body__text table__text text--capital text--primary"
              >
                {{ account.accountOfficer }}
              </span>
            </td>
            <td style="word-break: break-word; padding-right: 10px !important">
              <span
                class="table__body__text table__text text--capital text--primary"
              >
                {{ account.state }}
              </span>
            </td>
            <td style="word-break: break-word; padding-right: 10px !important">
              <span
                class="table__body__text table__text text--capital text--primary"
              >
                {{ account.repaymentNote ? account.repaymentNote : "" }}
              </span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.repaidDate | moment
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.principalDue | currency
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.principalPaid | currency
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.interestDue | currency
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.interestPaid | currency
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.feesDue | currency
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.feesPaid | currency
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.penaltyDue | currency
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.penaltyPaid | currency
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.totalDue | currency
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.totalPaid | currency
              }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="12">
              <div
                class="d-flex flex-column align-items-center justify-content-center p5"
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody
          v-if="allInstallments.metaData != '' && fetchAccount.isFromFilter"
        >
          <tr>
            <td
              style="
                border-top: 2px solid black !important;
                font-size: 0.4rem !important;
                padding-right: 0 !important;
              "
            >
              <div class="page__head__text">
                <h1
                  class="page__head2__title"
                  style="font-weight: bolder; font-size: 2rem"
                >
                  Summation:
                </h1>
              </div>
            </td>
            <td style="border-top: 2px solid black !important"></td>
            <td style="border-top: 2px solid black !important"></td>
            <td style="border-top: 2px solid black !important"></td>
            <td style="border-top: 2px solid black !important"></td>
            <td style="border-top: 2px solid black !important"></td>
            <td style="border-top: 2px solid black !important"></td>
            <td style="border-top: 2px solid black !important"></td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "principalDue")
                  | currency
              }}</span>
            </td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "principalPaid")
                  | currency
              }}</span>
            </td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "interestDue")
                  | currency
              }}</span>
            </td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "interestPaid")
                  | currency
              }}</span>
            </td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "feeDue") | currency
              }}</span>
            </td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "feePaid")
                  | currency
              }}</span>
            </td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "penaltyDue")
                  | currency
              }}</span>
            </td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "penaltyPaid")
                  | currency
              }}</span>
            </td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "totalDue")
                  | currency
              }}</span>
            </td>
            <td style="border-top: 2px solid black !important">
              <span class="table__body__text table__text text--capital">{{
                getSummationValue(allInstallments.metaData, "totalPaid")
                  | currency
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Pagination
      v-if="allInstallments.data != ''"
      :allItems="allInstallments"
      :size="fetchAccount.pageSize"
      @update-created-task="getUpdatedDeposits"
      @change-page-index="changePageIndex"
    />

    <!-- Filter -->
    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="getAllInstallments"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'All Installment'"
      filterCategory="installment"
    />

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  GET_ALL_INSTALLMENTS,
  GET_ALL_INSTALLMENT_EXPORTS,
} from "@/core/services/store/installments.module";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
// import JsonExcel from "vue-json-excel";
import Papa from "papaparse";
export default {
  name: "Installment",
  data: function () {
    return {
      pages: 1,
      size: 10,
      showAccountStatusModal: false,
      loading: false,
      account: {
        accountKey: "",
        accountStatus: "",
      },
      fetchAccount: {
        filters: [],
        pageIndex: 1,
        pageSize: 20,
        filterCategory: "installment",
        isFromFilter: false,
      },
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      json_fields: {
        "Due Date": "due_date",
        "Loan Name": "loan_name",
        "Account Holder Name": "name",
        State: "state",
        "Principal Due": "principal_due",
        "Interest Due": "interest_due",
        "Penalty Due": "penalty_due",
        "Fee Due": "fee_due",
      },
      showFilterModal: false,
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
    };
  },
  components: {
    // downloadExcel: JsonExcel,
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    CreateFilter: () =>
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      ),
  },
  watch: {
    allInstallments() {
      this.filterText = this.allInstallments.filterText
        ? this.allInstallments.filterText.replace(/_/g, " ")
        : "";
      this.filterEdit = this.allInstallments.filters
        ? this.allInstallments.filters
        : [];
      if (this.allInstallments.filters && this.allInstallments.filterText) {
        this.getExportData();
      }
    },
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    sendFilter(value) {
      const filterArray = value.map((obj) => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === "" || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchAccount.filters = filterArray;
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = 20;
      this.fetchAccount.filterCategory = "installment";
      this.fetchAccount.isFromFilter = true;
      // this.getAllInstallments();
      this.$store
        .dispatch(GET_ALL_INSTALLMENTS, this.fetchAccount)
        .then(() => {
          this.alertSuccess("Successfully applied filter");
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    getExportData() {
      if (this.allInstallments.totalCount > 0) {
        let data = this.fetchAccount;
        data.pageSize = this.allInstallments.totalCount;
        data.pageIndex = 1;
        this.$store
          .dispatch(GET_ALL_INSTALLMENT_EXPORTS, data)
          .then(() => {
            this.alertSuccess("Successfully applied filter");
          })
          .catch((error) => {
            this.alertError(error.response.data.message);
          });
      }
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = [];
    },
    editFilter() {
      this.showEditedValue = Math.random();
      this.toggleFilterModal();
    },
    getAllInstallments() {
      this.fetchAccount.filters = [];
      this.fetchAccount.isFromFilter = false;
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = 20;
      this.$store.dispatch(GET_ALL_INSTALLMENTS, this.fetchAccount);
    },
    filterAccountsByStatus() {
      return this.products;
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Installments Download",
        Subject: "Installments Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("Installments");

      let sheetData = [
        [
          "Due Date",
          "Loan Name",
          "Loan ID",
          "Account Holder Name",
          "Account Officer",
          "State",
          "Repayment Note",
          "Paid Date",
          "Principal Due",
          "Principal Paid",
          "Interest Due",
          "Interest Paid",
          "Fees Due",
          "Fees Paid",
          "Penalty Due",
          "Penalty Paid",
          "Total Due",
          "Total Paid",
        ],
      ];
      if (this.allInstallmentsExports && this.allInstallmentsExports.data) {
        this.allInstallmentsExports.data.map((item) => {
          const paidDate = item.paidDate
            ? moment(item.paidDate).format("Do-MMM-YYYY")
            : "";

          let new_arr = [];
          new_arr.push(moment(item.due).format("Do-MMM-YYYY"));
          new_arr.push(item.loanName);
          new_arr.push(item.loanId);
          new_arr.push(item.accountHolder);
          new_arr.push(item.accountOfficer);
          new_arr.push(item.state);
          new_arr.push(item.repaymentNote ? item.repaymentNote : "");
          new_arr.push(paidDate);
          new_arr.push(parseFloat(item.principalDue).toFixed(2));
          new_arr.push(parseFloat(item.principalPaid).toFixed(2));
          new_arr.push(parseFloat(item.interestDue).toFixed(2));
          new_arr.push(parseFloat(item.interestPaid).toFixed(2));
          new_arr.push(parseFloat(item.feesDue).toFixed(2));
          new_arr.push(parseFloat(item.feesPaid).toFixed(2));
          new_arr.push(parseFloat(item.penaltyDue).toFixed(2));
          new_arr.push(parseFloat(item.penaltyPaid).toFixed(2));
          new_arr.push(parseFloat(item.totalDue).toFixed(2));
          new_arr.push(parseFloat(item.totalPaid).toFixed(2));

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["Installments"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "installments.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Due Date", dataKey: "due_date" },
        { title: "Loan Name", dataKey: "loan_name" },
        { title: "Loan ID", dataKey: "loan_id" },
        { title: "Account Holder Name", dataKey: "name" },
        { title: "Account Officer", dataKey: "account_officer" },
        { title: "State", dataKey: "state" },
        { title: "Repayment Note", dataKey: "note" },
        { title: "Paid Date", dataKey: "paid_date" },
        { title: "Principal Due", dataKey: "principal_due" },
        { title: "Principal Paid", dataKey: "principal_paid" },
        { title: "Interest Due", dataKey: "interest_due" },
        { title: "Interest Paid", dataKey: "interest_paid" },
        { title: "Fees Due", dataKey: "fee_due" },
        { title: "Fees Paid", dataKey: "fee_paid" },
        { title: "Penalty Due", dataKey: "penalty_due" },
        { title: "Penalty Paid", dataKey: "penalty_paid" },
        { title: "Total Due", dataKey: "total_due" },
        { title: "Total Paid", dataKey: "total_paid" },
      ];
      let doc = new jsPDF("l", "pt", "a3");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("installments.pdf");
    },
    getSummationValue(objectData, type) {
      // const sum = arrayData
      //   .map((data) => data[type])
      //   .reduce((accumulator, value) => accumulator + value, 0);
      const sum = objectData[type];

      return sum;
    },
    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        let nextPage =
          this.allInstallments.hasNext || this.allInstallments.hasNextPage
            ? this.allInstallments.currentPage + 1
            : 0;
        this.fetchAccount.pageIndex = nextPage;
        this.fetchAccount.pageSize = pageSize;
        this.$store.dispatch(GET_ALL_INSTALLMENTS, this.fetchAccount);
      } else if (pages === "previous") {
        let nextPage =
          this.allInstallments.hasPrevious ||
          this.allInstallments.hasPreviousPage
            ? this.allInstallments.currentPage - 1
            : 0;
        this.fetchAccount.pageIndex = nextPage;
        this.fetchAccount.pageSize = pageSize;
        this.$store.dispatch(GET_ALL_INSTALLMENTS, this.fetchAccount);
      } else if (pages !== null) {
        this.fetchAccount.pageIndex = pages;
        this.fetchAccount.pageSize = pageSize;
        this.$store.dispatch(GET_ALL_INSTALLMENTS, this.fetchAccount);
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    changePageIndex(value, pageSize) {
      this.changeCurrentPage(value, pageSize);
    },
    getUpdatedDeposits(value) {
      this.fetchAccount.pageSize = value;
      this.$store.dispatch(GET_ALL_INSTALLMENTS, this.fetchAccount);
    },
    downloadCSVFile() {
      const jsonData = this.allDepositAccounts.data.map((a) => ({
        savingsAccountName: a.savingsAccountName,
        accountFirstName: a.accountFirstName,
        accountLastName: a.accountLastName,
        accountStatus: a.accountStatus,
        balance: a.balance,
      }));

      let blob = new Blob([Papa.unparse(jsonData)], {
        type: "text/csv;charset=utf-8;",
      });
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "deposit-account.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    viewRoute(data) {
      this.$router.push({ path: `/deposit-account/${data}` });
    },
  },
  computed: {
    ...mapState({
      allInstallments: (state) => state.installments.allInstallments,
      allInstallmentsExports: (state) =>
        state.installments.allInstallmentsExports,
      installmentLoading: (state) => state.installments.installmentLoading,
      user: (state) => state.auth.user,
    }),
    userCanExportToExcel() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
    download() {
      let data = [];
      this.allInstallmentsExports.data.map((item) => {
        let new_obj = {};
        new_obj.due_date = moment(item.due).format("DD-MM-YY");
        new_obj.loan_name = item.loanName;
        new_obj.loan_id = item.loanId;
        new_obj.name = item.accountHolder;
        new_obj.account_officer = item.accountOfficer;
        new_obj.state = item.state;
        new_obj.note = item.repaymentNote ? item.repaymentNote : "";
        new_obj.paid_date = item.paidDate
          ? moment(item.paidDate).format("DD-MM-YY")
          : "";
        new_obj.principal_due = parseFloat(item.principalDue).toFixed(2);
        new_obj.principal_paid = parseFloat(item.principalPaid).toFixed(2);
        new_obj.interest_due = parseFloat(item.interestDue).toFixed(2);
        new_obj.interest_paid = parseFloat(item.interestPaid).toFixed(2);
        new_obj.fee_due = parseFloat(item.feesDue).toFixed(2);
        new_obj.fee_paid = parseFloat(item.feesPaid).toFixed(2);
        new_obj.penalty_due = parseFloat(item.penaltyDue).toFixed(2);
        new_obj.penalty_paid = parseFloat(item.penaltyPaid).toFixed(2);
        new_obj.total_due = parseFloat(item.totalDue).toFixed(2);
        new_obj.total_paid = parseFloat(item.totalPaid).toFixed(2);

        data.push(new_obj);
      });

      return data;
    },
  },
  created() {
    this.getAllInstallments();
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>

<style scoped>
.button--sm.button--cancel span {
  font-size: 1rem;
}

._customtable {
  width: 100%;
}

._customtable thead th:first-of-type {
  width: 195px;
}

.button--cancel,
.button--grey.text--black {
  font-size: 1rem;
}
.cursor {
  cursor: pointer;
}
</style>
